<template>
  <div>
    <div class="main-box">
      <div class="text-start mb-26">
        <h1>使用索引</h1>
      </div>
      <div class="flex-between c-gray mb-56">
        <div v-if="currentItem.updateTime">
          {{ currentItem.updateTime | filterTime }}
        </div>
        <div>【来源：邦建通平台 】　　</div>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="操作手册" name="first">
          <div class="flex-row">
            <leftMenu :menuList="menuList" @meniItem="meniItem"></leftMenu>
            <div v-html="$options.filters.unescape(manualUrl)"></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="功能介绍" name="second">
          <div class="flex-row">
            <div class="left-menu">
              <el-tree
                :data="appList"
                :props="defaultProps"
                accordion
                @node-click="handleNodeClick"
              >
              </el-tree>
            </div>
            <div v-if="newList.length > 0">
              <video
                v-for="(item, index) in newList"
                :key="index"
                ref="viodes"
                class="video-box-item"
                controls
                :src="item.url"
              ></video>
            </div>
            <div v-else class="video-box-item" style="padding-top: 200px">
              <el-empty description="暂无内容"></el-empty>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane label="平台介绍" name="third">
          <div class="flex-row">
            <div class="left-menu">
              <el-tree
                :data="appList"
                :props="defaultProps"
                accordion
                @node-click="handleNodeClick"
              >
              </el-tree>
            </div>
            <div v-if="versionList.length > 0">
              <video
                v-for="(item, index) in versionList"
                :key="index"
                ref="viodes"
                class="video-box-item"
                controls
                :src="item.url"
              ></video>
            </div>
            <div v-else class="video-box-item" style="padding-top: 200px">
              <el-empty description="暂无内容"></el-empty>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import leftMenu from "./components/left-menu.vue";
import manual from "./components/manual.vue";
import videoProduct from "./components/video.vue";
import platformProduct from "./components/product.vue";
import {
  systemList,
  operationManul,
  bmanualTree,
  bmanualDetail,
  systemProductList,
  AdLaunchApp,
} from "@/api/engineering";

export default {
  data() {
    return {
      currentItem: {},
      manualUrl:
        "https://oss.yanxin7.cn/2023-08-25/ec25847d987e1d1871f6a83c87c370c3",
      leftMenus: [],
      activeName: "first",
      activeNames: "",
      functionList: [],
      platformList: [],
      operationList: [],
      menuList: [],
      newList: [],
      versionList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      appList: [],
    };
  },
  components: {
    manual,
    videoProduct,
    leftMenu,
    platformProduct,
  },
  filters: {
    unescape: function (html) {
      return html
        .replace(html ? /&(?!#?\w+;)/g : /&/g, "&amp;")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'");
    },
    filterTime(e) {
      let time = e.split(" ")[0];
      let day = `${time.split("-")[0]}年${time.split("-")[1]}月${
        time.split("-")[2]
      }日`;
      return day;
    },
  },
  mounted() {
    this.systemList();
    this.operationManul();
    this.bmanualTree();

    this.AdLaunchApp();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleNodeClick(e) {
      this.systemProductList(e.appid);
      console.log("app", e);
    },
    handleOpen() {},
    handleClose() {},
    handleChange() {},
    async systemList() {
      try {
        const { data } = await systemList({
          size: 100,
        });
        let records = data.records || [];
        let functionList = records.filter((item) => item.type == "new");
        let platformList = records.filter((item) => item.type == "version");
        console.log("dasdadasd", records);
      } catch (error) {
        console.log("获取资讯报错", error);
      }
    },
    async operationManul() {
      try {
        const { data } = await operationManul({
          size: 100,
        });
        let records = data.records || [];
        this.operationList = records;
      } catch (error) {
        console.log("获取资讯报错", error);
      }
    },
    // 获取功能介绍
    async systemProductList(appid) {
      try {
        const { data } = await systemProductList({ appid });
        if (data.records) {
          let newList = data.records.filter((item) => item.type == "new");
          let versionList = data.records.filter(
            (item) => item.type == "version"
          );
          this.newList = newList;
          this.versionList = versionList;
        }
        console.log("systemProductList", data);
      } catch (error) {
        console.log("systemProductListerror", error);
      }
    },
    // 获取手册详情
    async bmanualDetail(id) {
      try {
        const { data } = await bmanualDetail({ id });
        this.currentItem = data;
        console.log("urlllllll", data);
        this.manualUrl = data.content;
      } catch (error) {}
    },
    async bmanualTree() {
      try {
        const { data } = await bmanualTree({});
        this.menuList = data;
        console.log("bmanualTreebmanualTree", data[0]);
        this.bmanualDetail(data[0].id);
      } catch (error) {
        console.log("bmanualTreeerr", error);
      }
    },
    meniItem(e) {
      if (this.activeName == "first") {
        this.bmanualDetail(e.id);
      }
      if (this.activeName == "second" || this.activeName == "third") {
        this.systemProductList(e.appid);
      }
    },

    // 获取所有小程序
    async AdLaunchApp() {
      try {
        const { data } = await AdLaunchApp({ size: 66 });
        this.appList = data.records;
        this.systemProductList(data.records[0].appid);
        console.log("xxxxxxxxxxxxx", data);
      } catch (error) {}
    },
  },
};
</script>
<style scoped>
.main-box {
  padding-bottom: 40px;
  min-height: 800px;
  width: 1200px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 40px;
}
.main-content {
  display: flex;
}
.left-menu {
  width: 200px;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  background: #fbfbfb;
}
::v-deep .el-collapse-item__header {
  border: none;
  background: #fbfbfb;
}
::v-deep .is-current {
  color: #2288fc;
}
::v-deep .el-collapse-item__wrap {
  border: none;
  background: #fbfbfb;
}
::v-deep.el-collapse {
  border: none;
}
::v-deep .el-tabs__item {
  width: 120px !important;
  text-align: center;
}
.text-start {
  text-align: start;
}
.mb-26 {
  margin-bottom: 26px;
}
.c-gray {
  color: #888;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.mb-56 {
  margin-bottom: 26px;
}
.mb-24 {
  margin-bottom: 12px;
}
.collapse-item {
  cursor: pointer;
  position: relative;
}
.collapse-item::before {
  color: #007ef2;
  content: ".";
  position: absolute;
  display: block;
  width: 2px;
  height: 24px;
  background: #007ef2;
  opacity: 1;
}
.collapse-item:last-child {
  margin-bottom: 0px;
}
.flex-row {
  display: flex;
}
.video-box-item {
  width: 956px;
  height: 538px;
  object-fit: fill;
}
::v-deep .el-tree-node__content {
  height: 35px;
}
::v-deep .el-tree {
  background: #fbfbfb;
}
::v-deep .el-tabs__item {
  font-weight: bolder;
  font-size: 18px;
}
</style>