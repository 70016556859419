<template>
  <div class="flex-row">
    <div class="left-menu">
      <el-tree
        :data="menuList"
        :props="defaultProps"
        accordion
        @node-click="handleNodeClick"
      >
      </el-tree>
    </div>
  </div>
</template>
<script>
import { systemList, operationManul } from "@/api/engineering";
export default {
  props: {
    menuList: {
      type: Array,
      default: () => [{}],
    },
  },
  data() {
    return {
      activeName: "first",
      activeNames: "",
      leftMenus: [],
      activeName: "first",
      activeNames: "",
      functionList: [],
      platformList: [],
      operationList: [],
      data: [
        {
          label: "一级 1",
          children: [
            {
              label: "二级 1-1",
              children: [
                {
                  label: "三级 1-1-1",
                },
              ],
            },
          ],
        },
        {
          label: "一级 2",
          children: [
            {
              label: "二级 2-1",
              children: [
                {
                  label: "三级 2-1-1",
                },
              ],
            },
            {
              label: "二级 2-2",
              children: [
                {
                  label: "三级 2-2-1",
                },
              ],
            },
          ],
        },
        {
          label: "一级 3",
          children: [
            {
              label: "二级 3-1",
              children: [
                {
                  label: "三级 3-1-1",
                },
              ],
            },
            {
              label: "二级 3-2",
              children: [
                {
                  label: "三级 3-2-1",
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "appName",
      },
    };
  },
  components: {},
  mounted() {
    this.systemList();
    this.operationManul();
  },
  methods: {
    handleNodeClick(e) {
      this.$emit('meniItem',e)
    },
    async systemList() {
      try {
        const { data } = await systemList({
          size: 100,
        });
        let records = data.records || [];
        let functionList = records.filter((item) => item.type == "new");
        let platformList = records.filter((item) => item.type == "version");
        console.log("dasdadasd", records);
      } catch (error) {
        console.log("获取资讯报错", error);
      }
    },
    async operationManul() {
      try {
        const { data } = await operationManul({
          size: 100,
        });

        let records = data.records || [];
        this.operationList = records;
        console.log("dasda谢谢谢谢谢dasd", records);
      } catch (error) {
        console.log("获取资讯报错", error);
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleOpen() {},
    handleClose() {},
    handleChange(e) {
      console.log(e);
    },
  },
};
</script>
<style scoped>
.main-box {
  min-height: 800px;
  width: 1200px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 40px;
}
.main-content {
  display: flex;
}
.left-menu {
  width: 200px;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  background: #fbfbfb;
}
::v-deep .el-collapse-item__header {
  border: none;
  background: #fbfbfb;
}
::v-deep .el-collapse-item__wrap {
  border: none;
  background: #fbfbfb;
}
::v-deep.el-collapse {
  border: none;
}
::v-deep .el-tabs__item {
  width: 120px !important;
  text-align: center;
}
.text-start {
  text-align: start;
}
.mb-26 {
  margin-bottom: 26px;
}
.c-gray {
  color: #888;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.mb-56 {
  margin-bottom: 26px;
}
.mb-24 {
  margin-bottom: 12px;
}
.collapse-item {
  cursor: pointer;
  position: relative;
}
.collapse-item::before {
  color: #007ef2;
  content: ".";
  position: absolute;
  display: block;
  width: 2px;
  height: 24px;
  background: #007ef2;
  opacity: 1;
}
.collapse-item:last-child {
  margin-bottom: 0px;
}
.flex-row {
  display: flex;
}
</style>